"use client";

import { PropsWithChildren, useState } from "react";
import Image from "next/image";
import { Anchor } from "../Anchor";
import { icons } from "../SiteIcons";

/* eslint react/no-unescaped-entities: 0 */

export function K33LandingPageFAQ(props: {
  lowestEthRate: number;
  lowestBtcRate: number;
}) {
  const { lowestBtcRate, lowestEthRate } = props;

  const [open, setOpen] = useState<string | null>(null);

  const icon = icons.info;

  return (
    <div className="flex w-full max-w-[46.875rem] flex-col items-center justify-center gap-3 font-manrope">
      <div className="rounded-3xl bg-[#A54242] px-5 py-1 font-bold text-white">
        Q&A
      </div>
      <div className="mb-7 text-[3.25rem] font-bold">Questions & Answers</div>

      <div className="flex w-full flex-col">
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is a K33 loan?"
        >
          <div>
            K33 Loans helps you get a crypto-backed loan that allows you to
            borrow against your cryptocurrencies (known as “Crypto Assets” or
            “Digital Assets”) on the blockchain without selling them. Deposit
            your cryptocurrencies as collateral and take out loans in a USD
            stablecoin or convert it to your local currency (USD, EUR, GBP, CHF,
            NOK, SEK, DKK).
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 Loans are nominated and issued in the
              USD stablecoin{" "}
              <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                $DAI
              </Anchor>
              .
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is a stablecoin?"
        >
          <div>
            A stablecoin is cryptocurrency that has it’s price pegged to a
            specific asset — which is most often, but not always, the United
            States dollar.
            <br />
            <br />
            K33 Loans use the stable coin{" "}
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>{" "}
            which is pegged towards $USD.
            <Image
              src="/images/FAQ/stablecoin.png"
              width={232}
              height={70}
              alt="stablecoin"
              className=" mt-4"
            />
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is $DAI?"
        >
          <div>
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>{" "}
            is a stablecoin pegged to USD 1:1. Unlike traditional stablecoins
            (USDT, USDC), which are often backed and stabilized by fiat
            currencies held in reserve, $DAI maintains its value through a
            sophisticated system of smart contracts and collateral assets on the
            Ethereum blockchain. $DAI’s backing is overcollateralized which
            means there is always more value in locked collateral assets than
            value of circulating $DAI.
            <br />
            <br />
            $DAI is maintained and regulated by{" "}
            <Anchor href="https://sky.money">Sky</Anchor>, a decentralized
            autonomous organization (DAO) composed of the owners of its
            governance token, SKY, who may propose and vote on changes to
            certain parameters in its smart contracts in order to ensure the
            stability of $DAI.
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 does not warrant or guarantee any part
              of the functionalities offered by Sky.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How to create a K33 loan?"
        >
          <div>
            <ol
              type="1"
              className="flex list-inside list-decimal flex-col gap-2"
            >
              <li>
                Go to <Anchor href="/">loans.k33.com</Anchor>{" "}
              </li>
              <li>Launch App </li>
              <li>Sign in with BankID</li>
              <li>Enter your invite code</li>
              <li>Deposit cryptocurrencies to your K33 Loan wallet</li>
              <li>
                Go to {"->"} <b>Loan</b>{" "}
              </li>
              <li>Select collateral type (ETH or cbBTC) </li>
              <li>
                Input your <b>collateral amount</b>
              </li>
              <li>
                Select the amount of $DAI you wish to loan by using the “Adjust
                your loan” slider or entering an amount.
              </li>
              <li>
                Open <b>loan details</b> to see your <b>interest rate</b>,{" "}
                <b>liquidation LTV</b> and <b>loan maturity</b>.
              </li>
              <li>
                After you confirm the loan your K33 Loans wallet will be funded
                with $DAI. You have the flexibility to transfer these funds to
                your personal wallet at your convenience.{" "}
              </li>
              <li>
                If you would like to convert your $DAI to your local currency
                (USD, EUR, GBP, CHF, NOK, SEK, DKK), transfer the $DAI to your
                K33 Markets wallet and convert it to you local currency.
                Withdraw to your bank account at any time.
              </li>
              <li>
                Read and agree to the Terms and Conditions. Then, click [Create
                Loan].
              </li>
            </ol>
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 loans are nominated and issued in the
              USD stablecoin $DAI.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is Loan-to-Value (LTV)?"
        >
          <div>
            LTV is the ratio of your <u>Loan</u> to the value of your{" "}
            <u>Collateral</u>.
            <br />
            <br />
            When you borrow a loan, we calculate your loan amount or required
            collateral based on the following formula.
            <br />
            <br />
            LTV = <b>{"(Market Value of Loan Amount)"}</b> ÷{" "}
            <b>{"(Market Value of Collateral)"}</b>
            <br />
            <br />
            When a loan becomes active, we monitor your loan’s LTV based on the
            following formula.
            <br />
            <br />
            LTV = <b>{"(Market Value of Outstanding Balance)"}</b> ÷{" "}
            <b>{"(Market Value of Collateral)"}</b>
            <br />
            <br />
            where, <b>Outstanding Balance</b> ={" "}
            <b>Outstanding Loan Principal </b>+ <b>Outstanding Loan Interest</b>
            <br />
            <br />A composite index price is used to calculate the LTV every 5
            seconds, which incorporates price data from Sky. Based on the price
            data, we determine the composite index price and use this to value
            your crypto assets for LTV calculation.
            <br />
            <br />
            <br />
            <br />
            <b>Current LTV </b> → Current LTV is the current ratio of your loan
            to the value of your Collateral
            <br />
            <br />
            <b>Max LTV </b>
            → Maximum LTV is not your liquidation point, but is the maximum loan
            to value ratio that your debt can reach as a % of your collateral.
            <br />
            <br />
            <b>Liq LTV </b>→ Liquidation LTV is the debt collateral ratio at
            which your loan position will be liquidated. When the LTV ratio is
            higher than the Liq LTV ratio, K33 will
            <u>partially liquidate</u> your collateral assets to adjust your
            loan position to a healthy LTV → {">"}25% below Liq LTV. Please note
            that liquidation will be triggered when the corresponding Liq LTV
            ratio is reached.
            <br />
            <br />
            <i>Liquidation Fee</i>: In the event of a liquidation, you will be
            charged a 5% liquidation fee applied to the portion of the
            collateral that is liquidated.
            <br />
            <br />
            <b>Loan Health</b> → Your outstanding loan will have a Loan Heath
            indicator, which reflects the risk level based on your LTV.
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 will attempt to notify you when your
              Loan Health changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How does K33 help you manage your Loan?"
        >
          <div>
            Your loan will have a Loan Heath indicator, which reflects the risk
            level based on your LTV.
            <br />
            <br />
            A health status, which is based on your loan’s LTV and the defined
            thresholds, is provided to help you better manage your loan.
            <br />
            <br />
            K33 Loans will attempt to notify you on email and SMS when your Loan
            Health changes status.
            <br />
            <br />
            <br />
            <table className="table-fixed border-collapse border border-black">
              <tbody>
                <tr>
                  <th className="border border-black p-2">Loan’s LTV</th>
                  <th className="border border-black p-2">Health Status</th>
                  <th className="border border-black p-2">
                    Restricted Function
                  </th>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    {">"} 25% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      GOOD <div className="h-4 w-4 bg-success" />
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    25% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      FAIR <div className="h-4 w-4 bg-warning" />{" "}
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    15% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      POOR <div className="h-4 w-4 bg-danger" />{" "}
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">5% below Liq LTV</td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      CRITICAL <div className="h-4 w-4 bg-danger" />
                    </div>
                  </td>
                  <td className="border border-black p-2">Borrow</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 Loans will attempt to notify you when
              your Loan Health changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What happens when my loan reaches Liquidation LTV?"
        >
          <div>
            <b>Liq LTV </b>→ Liquidation LTV is the debt collateral ratio at
            which your loan position will be liquidated. When the LTV ratio is
            higher than the Liq LTV ratio, K33 will
            <u>partially liquidate</u> your collateral assets to adjust your
            loan position to a healthy LTV → {">"}25% below Liq LTV. Please note
            that liquidation will be triggered when the corresponding Liq LTV
            ratio is reached.
            <br />
            <br />
            <i>Liquidation Fee</i>: In the event of a liquidation, you will be
            charged a 5% liquidation fee applied to the portion of the
            collateral that is liquidated.
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 Loans will attempt to notify you when
              your Loan Health changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How do I improve my Loan-to-Value (LTV) Percentage?"
        >
          <div className="flex flex-col gap-2">
            <p>
              You may adjust your loan’s LTV (loan-to-value) at any time in 3
              ways: <b>Adjust</b> Collateral, <b>Increase</b> Loan, <b>Repay</b>{" "}
              Loan. An active loan’s LTV depends on the outstanding balance of
              the loan. Please note to keep your LTV-percentage low for better
              Loan Health.
            </p>
            <p>
              To <i>decrease</i> your Loan-to-Value (LTV) Percentage;
            </p>
            <p>
              1. Go to <b>Loan</b>
            </p>
            <p>
              2. Select <b>Adjust</b> → <b>Deposit Collateral</b>
            </p>
            <p>OR</p>
            <p>
              1. Go to <b>Loan</b>
            </p>
            <p>
              2. Select <b>Repay</b>
            </p>
            <br />
            <br />
            <p>
              To <i>increase</i> your Loan-to-Value (LTV) Percentage;
            </p>
            <p>
              1. Go to <b>Loan</b>
            </p>
            <p>
              2. Select <b>Adjust</b> → <b>Withdraw Collateral</b>
            </p>
            <p>OR</p>
            <p>
              1. Go to <b>Loan</b>
            </p>
            <p>
              2. Select <b>Increase Loan</b>
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How is my loan interest calculated?"
        >
          <div className="flex flex-col gap-2">
            <p>
              $DAI’s interest rate is a cost imposed by the Spark Protocol. It
              is a variable rate, known as APY (Annual Percentage Yield) that
              changes based on market conditions.
            </p>
            <p>
              $DAI’s interest rate accrues interest per second based on your
              outstanding loan balance. Interest is continuously added to your
              loan balance, compounding on previous interest charges.
            </p>
            <br />
            <p>
              <i>
                <b>Important Note:</b> $DAI’s interest rate is subject to change
                at any time.
              </i>
            </p>
            <p>
              Interest rate = <b>(Spark Dai Rate + K33 Loans Rate)</b>
            </p>
            <p>
              &emsp;Blockchain rate = <b>Spark Dai Rate% APY</b>
            </p>
            <p>
              &emsp;K33 Loans rate = <b>2.5% APY</b>
            </p>
            <br />
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How is my service fee calculated?"
        >
          <div className="flex flex-col gap-2">
            <p>
              K33 Service Fee accrues interest per second at 2.5% APY, based on
              your unpaid loan balance, compounding on previous interest
              charges.
            </p>
            <p>
              <b>Important Note: K33 Service Fee</b> is subject to change with 2
              months notice.
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How is my setup fee calculated?"
        >
          <div className="flex flex-col gap-2">
            <p>
              A K33 Loans setup fee of 0.5% charged to your unpaid loan
              principal upon loan creation.
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="Which currencies are available to loan?"
        >
          {" "}
          <p>
            All K33 loans are nominated and issued in USD stablecoin{" "}
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>
            . All K33 Loans are nominated and issued in USD stablecoin $DAI. You
            can convert your $DAI to your local currency (USD, EUR, GBP, CHF,
            NOK, SEK, DKK) at K33 Markets and withdraw to your bank account at
            any time.
          </p>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="Which cryptocurrencies are accepted as collateral?"
        >
          K33 Loans accepts ETH and cbBTC as collateral. We will be continually
          adding to this list!
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is cbBTC?"
        >
          <div className="flex flex-col gap-2">
            <p>
              cbBTC or Coinbase Wrapped BTC is an Ethereum (ERC20) token that is
              backed 1:1 by Bitcoin (BTC) held by Coinbase. Wrapped assets, like
              cbBTC, are transferable tokens that represent ownership of the
              underlying asset (BTC). cbBTC is fully transferable, and Coinbase
              customers can unwrap cbBTC and redeem a corresponding amount of
              the underlying BTC simply by depositing the cbBTC into their
              Coinbase accounts.
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How can I get cbBTC?"
        >
          <div className="flex flex-col gap-2">
            <p>
              Set up an account with Coinbase and deposit your BTC. Then
              withdraw your BTC to your cbBTC deposit address in your K33 Loans
              wallet. The same amount of BTC you withdrew from your Coinbase
              account will show up as cbBTC in your K33 Loans wallet.
            </p>
            <p>
              When Coinbase users send their BTC from Coinbase to an wallet
              address on Ethereum (like you K33 Loans wallet), that BTC will
              automatically be converted 1:1 to cbBTC. When users receive cbBTC
              in their Coinbase accounts, it will be converted 1:1 from cbBTC to
              BTC.
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How do I repay my loan?"
        >
          {" "}
          <div className="flex flex-col gap-2">
            <p>
              You can repay your loan at any time with any amount during the
              loan term. Your repayments of the outstanding loan (principal +
              interest) must be made in $DAI. Repayment must be done from your
              K33 Loans wallet. You can purchase $DAI through{" "}
              <Anchor href="https://k33.com/markets">K33 Markets</Anchor>.
            </p>
            <br />
            <p>
              1. Go to <b>Loan</b>
            </p>
            <p>
              2. Select <b>Repay</b> → <b>Insert Amount</b> → <b>Confirm</b>
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How long are the loan terms for a K33 loan?"
        >
          <p>K33 Loans run until they are repaid. </p>
          <p>K33 may require your loan to be repaid with 60 days notice.</p>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How can I withdraw my loan to my bank account?"
        >
          <div className="flex flex-col gap-2">
            <p>
              All K33 loans are nominated and issued in USD stablecoin $DAI. You
              can convert your $DAI to your local currency (USD, EUR, GBP, CHF,
              NOK, SEK, DKK) at K33 Markets and withdraw to your bank account at
              any time. You need to have stated a bank account in your K33
              Markets profile.
            </p>
            <p>1. Go to K33 Markets → Wallet </p>
            <p>2. Search $DAI → Copy $DAI deposit address</p>
            <p>3. Go to K33 Loans → Wallet</p>
            <p>4. Select $DAI → Withdraw</p>
            <p>5. Paste in K33 Markets $DAI deposit address</p>
            <p>6. Confirm withdrawal</p>
            <p>7. Go to K33 Markets - Trade</p>
            <p>8. Search $DAI → Select trading pair for your local currency</p>
            <p>9. Select Sell → Confirm</p>
            <p>
              10. Go to Wallet - Select local currency - Withdraw to you bank
              account.
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How does K33 loans work?"
        >
          <div className="flex flex-col gap-2">
            <p>
              K33 Loans helps you interact with the Spark Protocol on the
              Ethereum blockchain to create a <b>Spark Loan Position</b>. A
              Spark Loan Position is an individual borrowing account within the
              Spark Protocol where a user deposits cryptocurrency (e.g., ETH or
              cbBTC) as collateral to borrow $DAI. Here’s how it works;
            </p>
            <br />
            <p>
              1. You create a Spark Loan Position on Spark Protocol on the
              Ethereum blockchain through your K33 Loans account.
            </p>
            <p>
              2. The protocol generates $DAI based on your deposited collateral
              and LTV and sends it back to your K33 Loans wallet.
            </p>
            <p>
              3. Your $DAI loan at Spark accrues interest per second, as set by
              Spark, based on your unpaid loan principal. Interest is
              continuously added to your loan balance, compounding on previous
              interest charges.
              <br />
              In addition K33 Loans adds a service fee that accrues interest per
              second at an APY of 2.5%, based on your unpaid loan principal.
            </p>
            <p>
              4. You may adjust your loan’s LTV (loan-to-value) at any time in 3
              ways: <b>Adjust</b> Collateral, <b>Increase</b> Loan, <b>Repay</b>{" "}
              Loan.
            </p>
            <p>
              5. You can convert your $DAI to your local currency (USD, EUR,
              GBP, CHF, NOK, SEK, DKK) at K33 Markets and withdraw to your bank
              account at any time. You need to have stated a bank account in
              your K33 Markets
            </p>
            <p>
              6. When you repay your $DAI loan, K33 Loans sends your $DAI back
              to the Spark protocol and your collateral is released and sent
              back to your K33 Loans wallet. You can repay your loan at any time
              with any amount during the loan term by paying back the loan
              amount in $DAI. Your repayments of the outstanding loan (principal
              + interest) must be made in $DAI.
            </p>
          </div>
        </K33LandingPageFAQButton>
      </div>
      <div className="mt-2 flex flex-row gap-3 font-manrope text-[2F2D3B]">
        <Image
          alt={icon.alt}
          width={icon.width * 1.5}
          height={icon.height * 1.5}
          src={icon.path}
        />
        <p>
          Didn’t find the answer you are looking for?{" "}
          <a
            className="font-bold text-[#A54242] underline"
            href="mailto:support@loans.k33.com"
          >
            Contact our support
          </a>
        </p>
      </div>
    </div>
  );
}

function K33LandingPageFAQButton(
  props: {
    question: string;
    open: string | null;
    setOpen: (open: string | null) => void;
  } & PropsWithChildren
) {
  const open = props.open === props.question;

  return (
    <div id="button-container" className="flex flex-col">
      <button
        onClick={() => props.setOpen(open ? null : props.question)}
        id="question-container"
        className="flex min-h-[5.625rem] w-full cursor-pointer flex-row items-center justify-between gap-3 rounded-md border border-[#E0DFE5] border-opacity-50 px-7 py-5 text-start font-manrope text-2xl font-bold sm:py-0"
      >
        {props.question}
        <Image
          className={open ? "-rotate-90" : "rotate-90"}
          alt={"Arrow down"}
          width="10"
          height="35"
          src={"/images/icons/arrow-black.svg"}
        />
      </button>
      {open && (
        <div id="answer-container" className="p-7">
          {props.children}
        </div>
      )}
    </div>
  );
}
